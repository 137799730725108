
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import clubsMembersStub from "./clubs.members.stub"

export default Vue.extend({
  name: "ClubsMembersForm",

  props: {
    value: { type: Object, default: () => clubsMembersStub },
    club: { type: Object, required: false, default: (): null => null }
  },

  computed: {
    ...mapState("clubs/members", {
      clubsMembersStateCountries: "countries"
    }),
    ...mapState("clubs", {
      clubsStateList: "list",
      clubsStateListIsLoading: "listIsLoading"
    })
  },

  watch: {
    club: {
      immediate: true,
      handler(): void {
        this.clubsActionsList()
        if(!this.club) return
        this.value.clubId = this.club.id
      }
    },
    value: {
      deep: true,
      handler(data): void {
        this.$emit("input", data)
      }
    }
  },

  methods: {
    ...mapActions("clubs", {
      clubsActionsList: "list"
    }),

    submit(cb): void {
      this.$refs.localForm.validate()
        ? cb()
        : this.$error(this.$t("validationError"))
    },

    resetFields(): void {
      this.$refs.localForm.reset()
    }
  }
})
