
import Vue from "vue"

export default Vue.extend({
  name: "DataGrid",
  props: {
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    stats: []
  }),
  created(): void {
    this.stats = Object.keys(this.$slots)
  }
})
